import ScrollDown from "./ScrollDown";
import "./styles/services.css";
const Services = () => {
  const scrollDown = () => {
    window.scrollTo({
      top: (window.innerHeight - 80) * 4,
      behavior: "smooth",
    });
  };
  return (
    <div className="services-container">
      <h1>Services</h1>
      <h1>Under Construction</h1>
      <ScrollDown scrollTo={4} />
    </div>
  );
};

export default Services;
