import ScrollDown from "./ScrollDown";
import "./styles/contactme.css";
const ContactMe = () => {
  const scrollDown = () => {
    window.scrollTo({
      top: (window.innerHeight - 80) * 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="contactme-container">
      <h1>ContactMe</h1>
      <h1>Under Construction</h1>
      <ScrollDown text="Back To Top" scrollTo={0} />
    </div>
  );
};

export default ContactMe;
