import { ArrowDownward, Mouse } from "@mui/icons-material";
import { Button } from "@mui/material";
import ScrollDown from "./ScrollDown";
import "./styles/about.css";
const About = () => {
  return (
    <div className="about-content">
      <div className="about-content-image">
        <img
          className="about-img"
          src={require("../assets/home-image.png")}
          alt="about-image"
        />
      </div>
      <div className="about-content-text">
        <h1>About Me</h1>
        <p>
          I am a full stack developer with a passion for creating beautiful user
          experiences. <br /> I have a background in business and finance, and
          have worked with local businesses for over 10 years. <br /> I have a
          strong background in front-end development, and have worked with
          React, Redux, and Node.js. <br />I am currently working on a personal
          project called "The Daily" where I am building an inventory management
          website for a local business.
        </p>
        <ScrollDown scrollTo={2} />
      </div>
    </div>
  );
};

export default About;
