import ScrollDown from "./ScrollDown";
import "./styles/projects.css";
const Projects = () => {
  return (
    <div className="project-container">
      <h1>Projects</h1>
      <h1>Under Construction</h1>
      <ScrollDown scrollTo={3} />
    </div>
  );
};

export default Projects;
