import { ArrowDownward, ArrowUpward, Mouse } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./styles/scrollDown.css";
const ScrollDown = (props) => {
  const scrollDown = () => {
    window.scrollTo({
      top: (window.innerHeight - 80) * props.scrollTo,
      behavior: "smooth",
    });
  };
  return (
    <div className="home-scroll-down">
      <Button
        onClick={() => scrollDown(1)}
        className="scroll-down-button gradient-text"
      >
        <Mouse
          sx={{
            color: "rgb(137, 27, 241)",
          }}
        />
        {props.text ? props.text : "Scroll Down"}
        {props.text ? (
          <ArrowUpward sx={{ color: "rgb(191,132,223)" }} />
        ) : (
          <ArrowDownward sx={{ color: "rgb(191, 132, 223)" }} />
        )}
      </Button>
    </div>
  );
};

export default ScrollDown;
