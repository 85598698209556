import "./styles/sidebar.css";
import {
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Twitter,
} from "@mui/icons-material";
const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="blank"></div>
      <div className="social">
        <a href="https://www.instagram.com/rishikkshah" target="_blank">
          <Instagram />
        </a>
        <a href="https://www.twitter.com/rishikkshah" target="_blank">
          <Twitter />
        </a>
        <a href="https://www.facebook.com/rishikkshah" target="_blank">
          <Facebook />
        </a>
      </div>
      <div className="professional">
        <a href="https://www.linkedin.com/in/rishikkshah/" target="_blank">
          <LinkedIn />
        </a>
        <a href="https://www.github.com/rishikkshah" target="_blank">
          <GitHub />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
