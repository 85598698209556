import React, { useEffect, useState } from "react";
import "./App.css";
import About from "./components/About";
import ContactMe from "./components/Contactme";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Services from "./components/Services";
import Sidebar from "./components/Sidebar";

function App() {
  const [y, setY] = useState(0);
  window.onscroll = () => {
    setY(window.scrollY);
  };

  useEffect(() => {
    if (y / (window.innerHeight - 80) < 0.8) {
      document.querySelector("#home").classList.add("active");
      document.querySelector("#about").classList.remove("active");
    } else if (y / (window.innerHeight - 80) < 1.8) {
      document.querySelector("#about").classList.add("active");
      document.querySelector("#home").classList.remove("active");
      document.querySelector("#projects").classList.remove("active");
    } else if (y / (window.innerHeight - 80) < 2.8) {
      document.querySelector("#projects").classList.add("active");
      document.querySelector("#about").classList.remove("active");
      document.querySelector("#services").classList.remove("active");
    } else if (y / (window.innerHeight - 80) < 3.8) {
      document.querySelector("#services").classList.add("active");
      document.querySelector("#projects").classList.remove("active");
      document.querySelector("#contact").classList.remove("active");
    } else if (y / (window.innerHeight - 80) < 4.8) {
      document.querySelector("#contact").classList.add("active");
      document.querySelector("#services").classList.remove("active");
    }
  }, [y]);

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
        <Sidebar />
        <Home />
        <About />
        <Projects />
        <Services />
        <ContactMe />
      </header>
    </div>
  );
}

export default App;
