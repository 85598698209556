import { ArrowDownward, Mouse, Telegram } from "@mui/icons-material";
import { Button } from "@mui/material";
import ScrollDown from "./ScrollDown";
import "./styles/home.css";
const Home = () => {
  const scrollDown = (id) => {
    window.scrollTo({
      top: (window.innerHeight - 80) * id,
      behavior: "smooth",
    });
  };
  return (
    <div className="home-container">
      <div className="home-content">
        <div className="home-content-text">
          <h1>
            Hi, I'm <span className="highlight">Rishi</span>
          </h1>
          <h4>Full Stack Developer</h4>
          <p>
            High level of expertise in building web applications using
            technologies like React, Node, Express, MongoDB, and more.
          </p>
          <Button
            variant="contained"
            onClick={() => scrollDown(4)}
            className="gradient-background"
          >
            Contact me <Telegram />
          </Button>
          <ScrollDown scrollTo={1} />
        </div>
        <div className="home-content-image">
          <img
            className="home-img"
            src={require("../assets/home-image.png")}
            alt="home-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
