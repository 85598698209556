import "./styles/navbar.css";
const Navbar = () => {
  const scrollTo = (id) => {
    window.scrollTo({
      top: (window.innerHeight - 80) * id,
      behavior: "smooth",
    });
  };
  return (
    <div className="nav-container">
      <div className="nav-icon">
        <div>Rishi</div>
      </div>
      <div className="nav-links">
        <a onClick={() => scrollTo(0)} id="home">
          Home
        </a>
        <a id="about" onClick={() => scrollTo(1)}>
          About
        </a>
        <a onClick={() => scrollTo(2)} id="projects">
          Projects
        </a>
        <a onClick={() => scrollTo(3)} id="services">
          Services
        </a>
        <a onClick={() => scrollTo(4)} id="contact">
          Contact Me
        </a>
      </div>
    </div>
  );
};

export default Navbar;
